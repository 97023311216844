/**
 * main.ts
 * contains the whole logic of the project
 * uses classes that are defined 
 * in elements,components or layouts 
**/


var locations = null;

/**
 * Document-ready function
**/

$(document).ready(function(){
      
    $('.e-rte table').each(function() {
        var tableContent = $(this).html();
        $(this).replaceWith(function () {
            return $('<div class="table-responsive"><table>' + tableContent + '</table></div>');
        });
    });

    $(".js-main__tablesorter").each(function() {
        var rowCount = $('tr', this).length;
        console.log(rowCount);
        if(rowCount >= '5') {
            $(this).tablesorter(); 
        }
    });
    
    /**
     * Initalise function variables
    **/



	/* picturefill */
	
    picturefill(); 

    
    /* bootstrap popover */

    $('[data-toggle="popover"]').popover();


    /* Apply the polyfill for sticky elements (project-detail) */
    
    var elements = $('.sticky');
    Stickyfill.add(elements);


    
    /**
     * Basics
     * general scripts required for multiple components and elements
    **/

    let base = new Base();



    /**
     * Sidebar
    **/

    let sidebar = new Sidebar(); 
    
    

    /**
     * Flyout-nav
    **/

   let flyoutNav = new FlyoutNav();  



    /**
     * cookie message
    **/

   let cookieMessage = new CookieMessage(); 



   /**
     * forms
    **/

   let form = new Form();   

    

    /**
     * Navigation
     * Parameters:
     * accordionMode: Close already open menu tabs
     * openCurrent: open active menu tab on load
     * animationDuration
     * overviewpagesPossible: Possiblity to open a menu point directly
    **/

    let panelnav = new Panelnav(true, true, 500, false); 

	

	/**
     * Landingpagenavigation
     * Parameters:
     * animationDuration
	**/

   	if($('.js-l-body').hasClass('js-l-body--landingpage')) {
		let landingpagenavigation = new Landingpagenavigation(); 
	}


    /**
     * To-Top-Button
     * Parameters:
     * animationDuration
    **/

    let totop = new toTop();



    /**
     * Header
    **/

   let header = new Header();



    /**
     * Pageloader
     * Parameters:
     * maximumTimeVisible
    **/

    let pageloader = new pageLoader();



    /**
     * Hero component
     * Parameters:
     * animationDuration
     * scrollFactor
    **/

    let hero = new Hero(600,0.7);
    

    
    /**
     * Slider
     * Parameters:
     * maximumTimeVisible
    **/

    let slider = new Slider();

});
/**
 * base.ts
 * 
 * 
**/

class Base {

    /**
     * Define class variables
    **/
    
    
    /**
     * Constructor
    **/
    constructor () {
        
        this.watchEvents();
    }
    
    /*
    * Function XX
    * 
    */
    protected backLink():void {
        $('.js-base__back-link').on('click', function() {
            let backURL = $(this).data('back-url');
            if (document.referrer.indexOf(window.location.host) !== -1) { history.go(-1); return false; } else { window.location.href = backURL; }
        });
    }

    protected watchEvents():void {
        let self = this;
        self.backLink();
    }
}
class Form {
    constructor () {
        // set current class variable
        let self = this;
        let floatSelect = $('.e-form__select');
        let floatFile = $('.e-form__file');
        let floatTextarea = $('.e-form__textarea textarea');
        let floatTel = $('.e-form__input-tel');

		floatTel.each(function() {
			let self = $(this);
			let selfCurrentInput = self.find('input[type=tel]');
			let selfHiddenInput = self.find('input[type=hidden]');
			selfCurrentInput.on('input', function() {
				let values = '';
				selfCurrentInput.each(function() {
					values += $(this).val();
				});
				selfHiddenInput.val(values);
			});

		});

		// Select
		floatSelect.each(function() {
			let self = $(this);
			let selfSelect = $('select', this);
			let selfSelectID = selfSelect.attr('id');
			let selfSelectName = selfSelect.attr('name');

			// Add input with select ID
			selfSelect.after('<input type="text" id="'+ selfSelectID +'" placeholder=" " readonly required>');
			selfSelect.after('<input type="hidden" id="'+ selfSelectID +'VALUE" placeholder=" " name="'+ selfSelectName +'" readonly required>');
			// Add list for select options
			self.append('<ul class="e-form__select-options"></ul>');

			let selfSelectInput = self.children('input[type="text"]');
			let selfSelectInputHidden = self.children('input[type="hidden"]');
			let selfSelectOptionsWrapper = self.children('.e-form__select-options');

			let selfSelectOptions = selfSelect.find('option');

			// Fill List with content from select options
			selfSelectOptions.each(function() {
				
				let selfSelectOptionsContent = $(this).html();
				let selfSelectOptionValue = $(this).attr('value');

				if($(this).is(':selected')) {
					let selfSelectOptionsSelected = "active";	
					selfSelectInput.val(selfSelectOptionsContent);
					selfSelectInputHidden.val(selfSelectOptionValue);
				} else {
					let selfSelectOptionsSelected = "";	
				}


				selfSelectOptionsWrapper.append('<li class="' + selfSelectOptionsSelected + '" data-value="'+selfSelectOptionValue+'">'+ selfSelectOptionsContent +'</li>');
			});

			// click on select group open List with options
			self.on('mousedown touchstart', function () {
				if(selfSelectOptionsWrapper.is(":hidden")) {
                    $(this).addClass('select-open');
				}
			});

			// click on list - write selected option in input and close list
			self.find('li').on('click', function() {
				let selfSelectOptionsValue = $(this).data("value");
				selfSelectInput.val($(this).html());
                selfSelectInputHidden.val(selfSelectOptionsValue);
				floatSelect.removeClass('select-open');
				$('.e-form__select-options li').removeClass('active');
				$(this).addClass('active');
			});
			// original select remove because duplicate id
			selfSelect.remove();
		});

		//file
		floatFile.each(function() {
			let self = $(this);
			let selfFile = $('input[type="file"]', this);
			let selfFileID = selfFile.attr('id');

			// Add input with file ID
			selfFile.after('<input type="text" placeholder=" " disabled class="e-form__file-new">');
			let selfFileNew = self.children('.e-form__file-new');

			// Fill fake file-input with file-name
			selfFile.bind('change',function(){
				$(this).blur();
                var names = [];
                for (var i = 0; i < $(this).get(0).files.length; ++i) {
                    names.push($(this).get(0).files[i].name);
                }
                selfFileNew.val(names); 
			});

		});

        // Textarea autoresize
        floatTextarea.each(function() {
            var offset = this.offsetHeight - this.clientHeight;

            var resizeTextarea = function(el) {
                $(el).css('height', 'auto').css('height', el.scrollHeight + offset);
            };
            $(this).on('keyup input', function() { resizeTextarea(this); });
        });
    }
}
/**
 * to-top.ts
 * Logic of to-top button on page end
 * 
**/

class Header {

    /**
     * Constructor
    **/

    constructor () {

        /**
         * Initalise local constructor variables
        **/

        let self = this;



        /**
         * Call functions
        **/

        this.toggleSmallHeader();
        this.watchEvents();
    }



    /**
     * Function toggleSmallHeader
    **/


    protected toggleSmallHeader (documentHtml) {
        let target = window;
        let documentHtml = $('html');
    
        // get scroll position based for all browsers
        let scrollPos  = target.pageYOffset || target.scrollY || target.scollTop || document.getElementsByTagName("html")[0].scrollTop;
    
        // on scroll more than 9 pixel animate small
        if(scrollPos > 20) {
            if(!documentHtml.hasClass('js-l-header--small')) {
                documentHtml.addClass('js-l-header--small');
            }
        } else {
            if(documentHtml.hasClass('js-l-header--small')) {
                documentHtml.removeClass('js-l-header--small'); 
            }
        }
    }


    
    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/

   protected watchEvents():void {

        /**
         * Initalise function variables 
        **/

        let self = this;



        /**
         * Toggle HTML class' 
         * if page is scrolled
        **/

        $(window).on('scroll',function () {
            self.toggleSmallHeader();
        });

    }
}
/**
 * navigation.ts
 * Logic of navigation
**/



class Landingpagenavigation {

    /**
     * Initalise class variables
    **/

    public animationDuration:number;
    

    
    /**
     * Constructor
    **/

    constructor (animationDuration: number=1000) {

        /**
         * Initalise local constructor variables
        **/
       
        let self = this;



        /**
         * Set parameters
        **/

		self.animationDuration = animationDuration;



        /**
         * Call functions
        **/
        self.initializeMenu();
        self.watchEvents();
    }


   /**
     * Function initializeMenu():void
     * Initalize menu
     * Create HTML elements for landingpagenavigation
    **/

   public initializeMenu():void {

        /**
         * Initalise function variables
        **/

        let self = this;



        /**
         * Build navigation-frame for header- and burgernav 
        **/

        $('.js-landingpage__topic-nav').html('<ul class="l-topic-nav"></ul>');
        $('.js-landingpage__panel-nav ').html('<ul class="l-pagetree-panel"></ul>');



        /**
         * Build navigation-points for header- and burgernav 
        **/

        $('main section').each((index, element) => {
            if($(element).attr('id') != null){
                $('.l-topic-nav').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
                $('.l-pagetree-panel').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
            }
		});
		

		/* Set active state of menu-items */

        self.setActive();
    }


    
    /**
     * Function watchEvents():void
     * Watches all events of the landingpagenavigation and handles scrolling 
    **/

    public watchEvents():void {

        /**
         * Initalise function variables
        **/

        let self = this;


        
        /* Watch click on menu-items */

        $('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').on('click',function(event){
            let anchor = $(event.target).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
			let anchorScrollPosition =  anchorScrollTop - 150;
			
			if($('.js-l-body').hasClass('js-l-panel-nav--open')) {
				$('.l-panel-nav__backdrop').trigger('click');
			}

            $('html').stop().animate({
                scrollTop: anchorScrollPosition }, 300);
            event.preventDefault();
		});
		


		/* Watch scrolling of document for setting active state */

        $(window).on('scroll',function () {
            self.setActive();
        });
    } 



    /**
     * Function setActive():void
     * Set active state of menu-items
    **/
    
    public setActive():void {
		$('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').each((index, element) => {
			let anchor = $(element).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
            let parentItem = $(element).parent('li');
            let scrollCheckPoint = $(window).scrollTop() + 166;


            if(scrollCheckPoint >  (anchorScrollTop + $(anchor).outerHeight(true) ) ) {
                // Do nothing
            }
            else {
                if(anchorScrollTop < scrollCheckPoint)
                    parentItem.addClass('active').prev().removeClass('active');
                else 
                    parentItem.removeClass('active');
            }
        });
    }
}
class Slider {
    constructor () {

        // Slick-Slider initialize
        $('.js-slider__page').each(function() {
            $(this).on('init', function(event, slick){
                var modalID = $(".slick-current", this).data("target");
                $(this).append('<span class="c-slider__modal-open" data-toggle="modal" data-target="'+ modalID +'"></span>');
            });
        });

        $('.c-slider__modal').on('shown.bs.modal', function (e) {
            $('.js-slider__modal').slick('refresh');
            setTimeout(function(){
                $('.js-slider__modal').css("visibility", "visible");
            }, 200);
        });

        $('.js-slider__modal').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            asNavFor: '.js-slider__page',
            prevArrow: '<span class="slick-prev"></span>',
            nextArrow: '<span class="slick-next"></span>',
            customPaging : function(slider, i) {
                return '';
            }
        });

        $(".js-slider__page").slick({
            dots: true,
            asNavFor: '.js-slider__modal',
            prevArrow: '<span class="slick-prev"></span>',
            nextArrow: '<span class="slick-next"></span>',
            customPaging : function(slider, i) {
                return '';
            }
        });
    }
}
class Locations {
    public markers:any = [];  

    public initMap(mapid, locations) {
        var map = new google.maps.Map(document.getElementById(mapid), {
        zoom: 15,
        center: {lat: 0.0, lng: 0.0 },
        streetViewControl: false,
        styles: [
        // modify style: https://mapstyle.withgoogle.com/
        {
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#f5f5f5"
            }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
            {
                "visibility": "off"
            }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#616161"
            }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
            {
                "color": "#f5f5f5"
            }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#bdbdbd"
            }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [
            {
                "visibility": "off"
            }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#ffffff"
            }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#757575"
            }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#dadada"
            }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#616161"
            }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels",
            "stylers": [
            {
                "visibility": "off"
            }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#9e9e9e"
            }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#e5e5e5"
            }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#eeeeee"
            }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
            {
                "color": "#c9c9c9"
            }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
            {
                "color": "#9e9e9e"
            }
            ]
        }
        ]
        });

        bounds  = new google.maps.LatLngBounds();

        this.setMarkers(map, locations);
    }

    public setMarkers(map, locations) {
        for (var i = 0; i < locations.length; i++) {

        var location = locations[i];
        var infoContent = location[5];

        var image = {
            url: location[4]
        };

        var locationInfowindow = new google.maps.InfoWindow({
            content: infoContent,
            maxWidth: 300
        });

        var marker = new google.maps.Marker({
            position: {lat: location[1], lng: location[2]},
            map: map,
            icon: image,
            title: location[0],
            infowindow: locationInfowindow
        });

        this.markers.push(marker);

        google.maps.event.addListener(marker, 'click', function() {
            hideAllInfoWindows(map);
            this.infowindow.open(map, this);
        });

        loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(loc);

        }
        map.fitBounds(bounds);
        map.panToBounds(bounds);
        map.setOptions({maxZoom:16});
    }

    public hideAllInfoWindows(map) {
        this.markers.forEach(function(marker) {
            marker.infowindow.close(map, marker);
        }); 
    }
}
/**
 * hero.ts
 * Logic of hero component
**/



class Hero {

    /**
     * Initalise class variables
    **/

    public animationDuration:number;
    public scrollFactor:number;
    

    
    /**
     * Constructor
    **/

    constructor (animationDuration: number=1000, scrollFactor: number=0.7) {

        /**
         * Initalise local constructor variables
        **/
       
        let self = this;



        /**
         * Set parameters
        **/

		self.animationDuration = animationDuration;
		self.scrollFactor = scrollFactor;



        /**
         * Call functions
        **/

        self.watchEvents();
    }



    /**
     * Function watchEvents():void
     * Watches all events of the hero component and handles scrolling 
    **/

    public watchEvents():void {

        /**
         * Initalise function variables
        **/

        let self = this;



		/* Watch scroll event for text-box */
		
		$(window).scroll((e) => {
			this.parallax();
		});


		/* Watch click event on CTO button */

		$('.js-hero__scroll').on('click', (e) => {
            let headerHeight = $('.js-l-hero__header').height();
			$('html, body').stop().animate({
				scrollTop: $('.pimcore_hero').next().offset().top - headerHeight }, this.animationDuration);
			event.preventDefault();
		});
    } 



    /**
     * Function parallax():void
     * Set margin-top to text-box (small parallax effect)
    **/
    
    public parallax():void {

		/**
         * Initalise function variables
        **/

	   let self = this;
	   let scrolled = $(window).scrollTop();

	   
		/* Set margin of text-box */

		$('.l-hero__design--full .l-hero__text').css('margin-top',(scrolled*this.scrollFactor)+'px');
	}
}
/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
**/



class PagetreePanel {

    /**
     * Initalise class variables
    **/

    public accordionMode:any;
    public openCurrent:any;
    public animationDuration:any;
    public overviewpagesPossible:any;
    public menu:any;


    
    /**
     * Constructor
     * call 'new Panelmenu(true, true, 500, false)'
    **/

    constructor (accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {
       
        /**
         * Initalise local constructor variables
        **/
       
        let self = this;
        self.menu = $('.js-l-pagetree-panel');


        
        /**
         * Set parameters
        **/

        self.accordionMode = accordionMode;
        self.openCurrent = openCurrent;
        self.animationDuration = animationDuration;
        self.overviewpagesPossible = overviewpagesPossible;



        /**
         * Call functions
        **/

        self.initializeMenu();
        self.watchEvents();
    }



    /**
     * Function initializeMenu():void
     * Initalize menu
     * Set classes for open-states to elements
    **/

    public initializeMenu():void {

        /**
         * Initalise function variables
        **/

        let self = this;



        /**
         * Set additonal info classes to elements
         * add toggle-class to main link only if overviewpages are possible
        **/

        self.menu.find('li').has('ul').addClass('l-pagetree-panel__parent').children('a')
            .append('<span class="js-l-pagetree-panel__parent--toggle l-pagetree-panel__parent--toggle"></span>');

        if (self.overviewpagesPossible == false){
            self.menu.find('li').has('ul').children('a').addClass('js-l-pagetree-panel__parent--toggle').children('.l-pagetree-panel__parent--toggle')
                .removeClass('js-l-pagetree-panel__parent--toggle');
        }

        self.menu.find('li.l-pagetree-panel__item--active').has('ul').addClass('l-pagetree-panel__item-parent--active');
        


        /**
         * Set open-state to elements
        **/

       if(self.openCurrent == true) {
            $('li.js-l-pagetree-panel__item--active').each(function() {
                if ($(this).children('a').hasClass('active-trail')){
                    $(this).addClass('js-l-pagetree-panel__item-parent--open');
                }
            });
            self.menu.find('li.js-l-pagetree-panel__item-parent--open').has('ul').children('ul').slideDown();
        }  
    }



    /**
     * Function watchEvents():void
     * Watches all events of the panelmenu and handles toggeling 
    **/

    public watchEvents():void {

        /**
         * Initalise function variables
        **/

        let self = this;
        let toggleButton = $('.js-l-pagetree-panel__parent--toggle');


        
        /**
         * Watch for clicks on menu-parent item and 
         * handle toogling of menus and submenus
        **/

        toggleButton.on('click touchend', function(e:any){
            let currentMenu = $(this).closest('li');
            e.preventDefault();


            /**
             * Handling of click on already opened menu
             **/

            if(currentMenu.hasClass('js-l-pagetree-panel__item-parent--open')){
                currentMenu.removeClass('js-l-pagetree-panel__item-parent--open')
                    .children('ul').stop().slideUp(self.animationDuration)
                    .find('li.js-l-pagetree-panel__item-parent--open')
                    .removeClass('js-l-pagetree-panel__item-parent--open')
                    .children('ul').stop().slideUp(self.animationDuration);
            } else {



                /**
                 * If accordion mode is active close all other panels in menu
                 * unless clicked menu is submenu of already opend 
                **/
                
                if(self.accordionMode) {
                    if(!currentMenu.parents().hasClass('js-l-pagetree-panel__item-parent--open')){
                        self.menu.find('li.js-l-pagetree-panel__item-parent--open')
                        .removeClass('js-l-pagetree-panel__item-parent--open')
                        .find('ul').stop().slideUp(self.animationDuration);
                    }
                }



                /**
                 * Open clicked menu
                **/
               
                currentMenu.addClass('js-l-pagetree-panel__item-parent--open').children('ul').stop().slideToggle(self.animationDuration);
            }
        });
    }
}
/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
**/



class PagetreeSub {

    /**
     * Define class variables
    **/
    
    
    /**
     * Constructor
    **/
    constructor () {
            
        this.watchEvents(); 

    }

    /* 
    * Function XX
    * 
    */
   protected toggleNav():void {
        $('.js-l-pagetree-sub__opener').on('click touchend', function (e) {
            let subNavID = $(this).data('subnav-primary');

            $('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');
            $(this).addClass('l-pagetree-sub__item--open');
            $('.l-pagetree-sub__secondary').hide();
            $('*[data-subnav-secondary="' + subNavID + '"]').show();

            return false;
        });
    }

    protected activeNav():void {
        $('.js-l-pagetree-sub__opener').each(function (e) {
            if($(this).hasClass('l-pagetree-sub__item--active')) {
                let subNavID = $(this).data('subnav-primary');
                $('*[data-subnav-secondary="' + subNavID + '"]').show();   
            }
        });
    }

    protected watchEvents():void {
        let self = this;
        self.toggleNav();
        self.activeNav();

        $('.js-l-flyout-nav__opener').on('click touchend', function (e) {
            $('.l-pagetree-sub__secondary').hide();
            $('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');
            
            self.activeNav();
        });            
    }
}

/**
 * sidebar.ts
 * 
 * 
**/

class CookieMessage {

    /**
     * Define class variables
    **/
    
    
    /**
     * Constructor
    **/
    constructor () {
        
        this.watchEvents(); 
    }
    
    /*
    * Function XX
    * 
    */
    protected showMessage():void {
        if(document.cookie.indexOf('hideCookieMessage=1') != -1){
            $('.l-cookie-message').addClass('close');
        }
        else{
            $('l-cookie-message').removeClass('close');
        } 
    }
    
    protected closeMessage():void {
        $('.l-cookie-message__button-ok').on('click', function() {
            $(this).parents('.l-cookie-message').addClass('close'); 
            document.cookie = 'hideCookieMessage=1';
            return false;
        }); 
    }

    protected watchEvents():void {
        let self = this;
        self.showMessage();
        self.closeMessage();
    }
}
/**
 * sidebar.ts
 * 
 * 
**/

class FlyoutNav {

    /**
     * Define class variables
    **/
    
    
    /**
     * Constructor
    **/
    constructor () {
        
        this.watchEvents(); 


        /**
         * Get instance of PagetreeSecondary
        **/

       let pagetreeSub = new PagetreeSub(); 
    }
    
    /*
    * Function XX
    * 
    */
    protected toggleNav():void {
        $('.js-l-flyout-nav__opener').on('click touchend', function (e) {
            console.log('open');
            let mainNavID = $(this).data('mainnav-main'); 
            let mainNavID2 = 'navID' + mainNavID;
            console.log(mainNavID);

            $('.l-pagetree-sub').hide();
            $('*[data-mainnav-sub="' + mainNavID + '"]').show();

            if($('.js-l-flyout-nav').hasClass('l-flyout-nav__open')) {
                let openID = $('.js-l-flyout-nav').attr('id');

                if (openID == mainNavID2) {
                    $('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
                    $(this).removeClass('l-pagetree-main__item--open'); 
                } else {
                    $('.js-l-flyout-nav').removeClass('l-flyout-nav__open');
                    $('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
                    
                    $('.js-l-flyout-nav').addClass('l-flyout-nav__open');  
                    $('.js-l-flyout-nav').attr('id', mainNavID2);
                    $(this).addClass('l-pagetree-main__item--open'); 
                    
                }
                
            } else {
                $('.js-l-flyout-nav').addClass('l-flyout-nav__open');  
                $('.js-l-flyout-nav').attr('id', mainNavID2);
                $(this).addClass('l-pagetree-main__item--open');
            }

            return false;
        });

        $('.js-l-page__content').on('click touchend', function (e) {
            $('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
            $('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open');
        });
    }

    protected closeNav():void {
        $('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
        $('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
    }

    protected watchEvents():void {
        let self = this;
        self.toggleNav();

        $(window).scroll((e) => {
			this.closeNav();
        });
        
        $(window).on('resize',function (e) {
            $('.js-l-flyout-nav').removeClass('l-flyout-nav__open'); 
            $('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 
        });
    }
}
/**
 * pageloader.ts
 * Logic of pageloader (spinner)
 * 
**/

class pageLoader {

   /**
     * Define class variables
    **/

   public maximumTimeVisible:number; 



    /**
     * Constructor
    **/

    constructor (maximumTimeVisible: number=3000) {

        /**
         * Initalise local constructor variables
        **/

        let self = this;



        /**
         * Set parameters
        **/

        self.maximumTimeVisible = maximumTimeVisible;



        /**
         * Call functions
        **/

        /* Hide loader after page is fully loaded */
         $(window).on('load', function(){
            self.hideLoader();
        });

        /* Hide loader after maximumtime if page is loading too long */
        setTimeout(function() {
            self.hideLoader();   
        }, self.maximumTimeVisible);
    }



    /**
     * Function hideLoader():void
     * hide spinner after page is fully loaded
    **/

    protected hideLoader():void {
         $('.js-l-pageloader').fadeOut(100); 
    }
}
/**
 * navigation.ts
 * Logic of navigation
**/



class Panelnav {

    /**
     * Initalise class variables
    **/

    public scrollbarWidth:any;
    

    
    /**
     * Constructor
    **/

    constructor (accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {

        /**
         * Initalise local constructor variables
        **/
       
        let self = this;



        /**
         * Call functions
        **/

        self.measureScrollbar();
        self.watchEvents();



        /**
         * Get instance of panelmenu
        **/

        let pagetreePanel = new PagetreePanel(accordionMode, openCurrent, animationDuration, overviewpagesPossible);
    }


    
    /*
    * Function measureScrollbar():void
    * Measures scrollbar width of browser and adds a div 
    * that prevents jumping of content
    */

    public measureScrollbar():void {

        /**
         * Initalise function variables 
        **/

        let self = this;
        let scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        self.scrollbarWidth = window.innerWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }



    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/

    public watchEvents():void {

        /**
         * Initalise function variables 
        **/

        let self = this;



        /**
         * Toggle bodyclass 'js-l-panelmenu--open' 
        **/

		$('.js-l-pagetree-panel__opener, .js-l-panel-nav__backdrop, .js-panel-nav__close').on('click touchend', function (e) {
			e.preventDefault();
			if($('.js-l-body').hasClass('js-l-panel-nav--open')) {
				$('.js-l-body').removeClass('js-l-panel-nav--open').css({marginRight: '0px'});
				$('.l-header__wrapper').css({paddingRight: '0px'});
			} else {
				$('.js-l-body').addClass('js-l-panel-nav--open').css({marginRight: self.scrollbarWidth + 'px'});
				$('.l-header__wrapper').css({paddingRight: self.scrollbarWidth + 'px'});
			}
			// $('html').toggleClass('openNavi');
			$('.js-l-panel-nav__backdrop').fadeToggle();
		});
    }
}
/**
 * sidebar.ts
 * 
 * 
**/

class Sidebar {

    /**
     * Define class variables
    **/
    
    
    /**
     * Constructor
    **/
    constructor () {
        
        this.watchEvents();
    }
    
    /*
    * Function XX
    * 
    */
    protected openSidebar():void {
        $('.l-sidebar__icon').on('click', function() {
            $('.l-sidebar__item').not($(this).parent()).removeClass('open');
            $(this).parent('.l-sidebar__item').toggleClass('open'); 
            
        });
    }

    protected hideSidebar():void {
        let windowHeight = $(window).height();

        if(windowHeight <= "500") {
            $(".l-sidebar").hide();
        } else {
            $(".l-sidebar").show();
        }
    }

    protected watchEvents():void {
        let self = this;
        self.openSidebar();
        self.hideSidebar();

        $(window).on('resize',function () {
            self.hideSidebar();
        });
    }
}
/**
 * to-top.ts
 * Logic of to-top button on page end
 * 
**/

class toTop {

    /**
     * Define class variables
    **/

    public animationDuration:number; 
    


    /**
     * Constructor
    **/

    constructor (animationDuration: number=900) {

        /**
         * Initalise local constructor variables
        **/

        let self = this;



        /**
         * Set parameters
        **/

        self.animationDuration = animationDuration;



        /**
         * Call functions
        **/

        this.checkVisibility();
        this.watchEvents();
    }



    /**
     * Function initAction():void
     * set/reset to-top classes
    **/

    protected checkVisibility():void {
        if($(window).scrollTop() > 9)
            $('.js-l-to-top').addClass('l-to-top--active');
        else 
            $('.js-l-to-top').removeClass('l-to-top--active');
    }


    
    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/

   protected watchEvents():void {

        /**
         * Initalise function variables 
        **/

        let self = this;



        /**
         * Toggle bodyclass 'js-l-to-top--visible' 
         * if page is scrolled
        **/

        $(window).on('scroll',function () {
            self.checkVisibility();
        });


        
        /**
         * Animate HTML to top 
        **/
       
        $('.js-l-to-top').on('click',function(event) {
            $('html').stop().animate({
                scrollTop: 0}, self.animationDuration);
            event.preventDefault();
        }); 
    }
}